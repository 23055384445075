import React, { useState } from 'react';
import './FileUploader.css';  // Assuming the CSS file is in the same folder


function FileUploader() {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);  // State to handle loading indicator

    const handleFileChange = event => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        if (file) {  // Ensure there is a file to upload
            const formData = new FormData();
            formData.append('file', file);

            setLoading(true);  // Start loading
            fetch('https://opportunity-product.bestinbrands.co/upload', {
                method: 'POST',
                body: formData
            })
            .then(response => response.text())
            .then(data => {
                alert(data);  // Show alert with response data
                setLoading(false);  // Stop loading after data is received
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);  // Stop loading on error
            });
        } else {
            alert('Please select a file to upload.');
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} accept=".csv" />
            <button onClick={handleFileUpload} disabled={loading}>
                {loading ? 'Uploading...' : 'Upload CSV here'}
            </button>
            {loading && <div className="loading-icon"></div>}
        </div>
    );
}

export default FileUploader;